export const navigatePath = {
  index: '/',
  login: '/login',
  dashboard: '/dashboard',
  daily: '/dashboard/daily',
  createDaily: '/dashboard/daily/create',
  editDaily: '/dashboard/daily/:id/edit',
  fbVideo: '/dashboard/fb_video',
  productModel: '/dashboard/product_model',
  manuallyAddArticles: '/dashboard/manually_add_articles',
  category: '/dashboard/category',
  banUser: '/dashboard/ban_user',
  createBanUser: '/dashboard/ban_user/create',
}

export const routeConfig = {
  root: {
    index: '/',
    login: '/login',
    error: '/error',
    dashboard: '/dashboard',
  },
  dashboard: {
    index: '/',
    daily: '/daily',
    productModel: '/product_model',
    fbVideo: '/fb_video',
    manuallyAddArticles: '/manually_add_articles',
    category: '/category',
    banUser: '/ban_user',
  },
  daily: {
    index: '/',
    create: '/create',
    edit: '/:id/edit',
  },
  productModel: {
    index: '/',
    create: '/create',
    edit: '/:id/edit',
  },
  manuallyAddArticles: {
    index: '/',
  },
  category: {
    index: '/',
    create: '/create',
    edit: '/:id/edit',
  },
  banUser: {
    index: '/',
    create: '/create',
  },
}
