import React from 'react'
import { queryCache, QueryStatus, useMutation, useQuery } from 'react-query'
import { useUserToken, useUserUid, useValidated } from 'src/_contexts/user-info.context'
import { FetchFactory } from 'src/_utils/fetch-utils'
import { createLogger } from 'src/_utils/logger'
import { BanUserFormInputs, User } from './types'

const logger = createLogger({ namespace: 'daily.service', logLevel: 'warn' })

export const BAN_USER_QUERY_KEY = 'ban-user'

interface BanUserResponse {
  items: User[]
  total: number
}

export function useBanUserList() {
  const validated = useValidated()
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const [searchMid, setSearchMid] = React.useState('')

  const { data, status, error, refetch } = useQuery<BanUserResponse, string>(
    [BAN_USER_QUERY_KEY, page, pageSize, searchMid],
    () => {
      const url = `/v2/popadmin/banned-users?page=${page}&pageSize=${pageSize}${
        searchMid ? `&mid=${searchMid}` : ''
      }`
      return request.get(url)
    },
    {
      enabled: false,
      retry: false,
    },
  )

  React.useEffect(() => {
    if (validated) {
      refetch()
    }
  }, [validated, refetch, page, pageSize, searchMid])

  const handlePageChange = React.useCallback((newPage: number, newPageSize: number) => {
    setPage(newPage)
    setPageSize(newPageSize)
  }, [])

  const handleSearchMid = React.useCallback((mid: string) => {
    setSearchMid(mid)
    setPage(1) // Reset to first page when searching
  }, [])

  const emptyItems = React.useRef({ items: [], total: 0 })
  const items = status === QueryStatus.Success ? data || emptyItems.current : emptyItems.current

  return [
    {
      items: items.items,
      pagination: {
        current: page,
        pageSize,
        total: items.total,
      },
      onPageChange: handlePageChange,
      onSearchMid: handleSearchMid,
      searchMid,
    },
    status,
    error,
  ] as const
}

export function useBanUserSubmit() {
  const operationUid = useUserUid()
  const userToken = useUserToken()
  const request = new FetchFactory({ userToken })

  const [banUser, { error, isSuccess, isLoading }] = useMutation<any, Error, BanUserFormInputs>(
    (payload) =>
      request.put(`/v2/popadmin/users/${payload.mid}/ban?via=後台&operationUid=${operationUid}`, {
        reason: payload.reason,
      }),
    {
      onSuccess() {
        queryCache.refetchQueries(BAN_USER_QUERY_KEY)
      },
    },
  )

  return { submit: banUser, isLoading, error, isSuccess }
}
