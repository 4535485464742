import { Button, Col, Row, Table, Typography, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import type { ColumnsType } from 'antd/lib/table'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router'
import { navigatePath } from 'src/_constants/routes'
import { User } from '../types'

interface Props {
  items: User[]
  isLoading: boolean
  pagination: {
    current: number
    pageSize: number
    total: number
  }
  onPageChange: (page: number, pageSize: number) => void
  onSearchMid?: (mid: string) => void
  searchMid?: string
}

export default function BanUserList({
  items,
  isLoading,
  pagination,
  onPageChange,
  onSearchMid,
  searchMid = '',
}: Props) {
  const navigate = useNavigate()
  const [midSearch, setMidSearch] = React.useState(searchMid)

  const handleSearch = () => {
    if (onSearchMid) {
      onSearchMid(midSearch)
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleClear = () => {
    setMidSearch('')
    if (onSearchMid) {
      onSearchMid('')
    }
  }

  React.useEffect(() => {
    setMidSearch(searchMid)
  }, [searchMid])

  return (
    <>
      <Row>
        <Col span={12}>
          <Typography.Title level={2}>封鎖使用者 - 列表</Typography.Title>
        </Col>
        <Col offset={8} span={4}>
          <a href={navigatePath.createBanUser}>
            <Button type="primary" block>
              封鎖使用者
            </Button>
          </a>
        </Col>
      </Row>

      <Row style={{ marginBottom: 16 }}>
        <Col span={8} offset={16}>
          <Space>
            <Input
              placeholder="搜尋使用者MID"
              value={midSearch}
              onChange={(e) => setMidSearch(e.target.value)}
              onKeyPress={handleKeyPress}
              style={{ width: 200 }}
              suffix={
                searchMid ? (
                  <Button type="text" size="small" onClick={handleClear}>
                    清除
                  </Button>
                ) : null
              }
            />
            <Button type="primary" icon={<SearchOutlined />} onClick={handleSearch}>
              搜尋
            </Button>
          </Space>
        </Col>
      </Row>

      <Table
        rowKey="uid"
        columns={columns}
        dataSource={items}
        bordered
        loading={isLoading}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          showTotal: (total) => `共 ${total} 筆`,
        }}
        onChange={(pagination) => {
          onPageChange(pagination.current || 1, pagination.pageSize || 10)
        }}
      />
    </>
  )
}

const columns: ColumnsType<User> = [
  {
    title: '使用者',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '使用者mid',
    dataIndex: 'mid',
    key: 'mid',
  },
  {
    title: '封鎖時間',
    dataIndex: 'bannedAt',
    key: 'bannedAt',
    render: (text: string) => (text ? moment(text).format('YYYY-MM-DD HH:mm') : '未設定'),
    sorter: (a, b) => moment(a.bannedAt).valueOf() - moment(b.bannedAt).valueOf(),
  },
  {
    title: '封鎖者',
    dataIndex: 'operatorName',
    key: 'operatorName',
  },
  {
    title: '封鎖者mid',
    dataIndex: 'operatorMid',
    key: 'operatorMid',
  },
  {
    title: '操作管道',
    dataIndex: 'via',
    key: 'via',
    render: (text: string) => {
      if (!text) return 'Slack'
      return text
    },
  },
  {
    title: '原因',
    dataIndex: 'reason',
    key: 'reason',
  },
]
