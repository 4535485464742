import React from 'react'
import constate from 'constate'
import { useLocalStorageState } from 'src/_utils/useLocalStorageState'

export interface UserInfo {
  /**
   * User ID
   */
  uid?: string

  /**
   * Member ID 對外的使用者代號
   * PS: uid (User ID) 已 deprecate
   */
  mid?: string

  /**
   * Session token
   */
  token: string

  /**
   * 使用者名稱
   */
  name?: string
}

function useUserInfo() {
  const [userInfo, setUserInfo] = useLocalStorageState<UserInfo, null>('popdaily::user-info', null)
  const [validated, setValidated] = React.useState(false)
  return { userInfo, validated, setUserInfo, setValidated }
}

const [
  UserInfoProvider,
  useUserInfoContext,
  useValidated,
  useUserToken,
  useUserMid,
  useUserUid,
] = constate(
  useUserInfo,
  (value) => value,
  (value) => value.validated,
  (value) => value.userInfo?.token,
  (value) => value.userInfo?.mid,
  (value) => value.userInfo?.uid,
)

export { UserInfoProvider, useUserInfoContext, useValidated, useUserToken, useUserMid, useUserUid }
