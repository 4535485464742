import React, { useEffect } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { navigatePath } from 'src/_constants/routes'
import { useBanUserSubmit } from '../service'
import { BanUserFormInputs } from '../types'

interface BanUserFormProps {
  onCancel: () => void
}

export default function BanUserForm({ onCancel }: BanUserFormProps) {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { submit, isLoading, error, isSuccess } = useBanUserSubmit()

  const handleSubmit = async (values: BanUserFormInputs) => {
    try {
      const confirmed = window.confirm('確定要封鎖此使用者嗎？')
      if (!confirmed) return
      await submit(values)
    } catch (error) {
      message.error('封鎖使用者失敗，請稍候再試一次\n' + (error as Error).message)
    }
  }

  useEffect(() => {
    if (error) {
      message.error('封鎖使用者失敗，請稍候再試一次\n\n' + (error as any)?.errors[0]?.message)
    }
  }, [error])

  useEffect(() => {
    if (isSuccess) {
      message.success('使用者已成功封鎖')
      navigate(navigatePath.banUser)
    }
  }, [isSuccess])

  return (
    <>
      <Form
        form={form}
        name="banUser"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="使用者MID"
          name="mid"
          rules={[{ required: true, message: '請輸入使用者MID' }]}
        >
          <Input placeholder="請輸入要封鎖的使用者MID" />
        </Form.Item>

        <Form.Item
          label="封鎖使用者原因"
          name="reason"
          rules={[{ required: true, message: '請輸入封鎖原因' }]}
        >
          <Input.TextArea rows={4} placeholder="請輸入封鎖原因" />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
          <Button type="primary" htmlType="submit" loading={isLoading} style={{ marginRight: 8 }}>
            確認封鎖
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </Form.Item>
      </Form>
    </>
  )
}
