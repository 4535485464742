import React from 'react'
import { useNavigate, Outlet } from 'react-router'
import { navigatePath } from 'src/_constants/routes'

import List from './components/BanUserList'
import BanUserForm from './components/BanUserForm'

import { useBanUserList } from './service'

export function BanUser() {
  return <Outlet />
}

export function BanUserList() {
  const [{ items, pagination, onPageChange, onSearchMid, searchMid }, status] = useBanUserList()

  return (
    <List
      items={items}
      isLoading={status === 'loading'}
      pagination={pagination}
      onPageChange={onPageChange}
      onSearchMid={onSearchMid}
      searchMid={searchMid}
    />
  )
}

export function CreateBanUser() {
  const navigate = useNavigate()
  const handleCancel = React.useCallback(() => navigate(navigatePath.banUser), [navigate])

  return (
    <>
      <h2>封鎖使用者</h2>
      <BanUserForm onCancel={handleCancel} />
    </>
  )
}
